import React from "react";

import PageWrapper from "../components/PageWrapper";
import {Section, Title} from "../components/Core";
import {Col, Container, Row} from "react-bootstrap";

const IndexPage = () => {
    return (
        <>
            <PageWrapper>
                <Section hero className="position-relative">
                    <Container>
                        <Row>
                            <Col><Title>404 Not found </Title></Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default IndexPage;
