import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col, Spinner} from "react-bootstrap";

import { Section, Title, Text} from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import {Button} from "../components/Core";
import GlobalContext from "../context/GlobalContext";
import Slider from "react-slick";
import ImageZoom from 'react-image-zooom';
import WorkCard from "../components/WorkCard";
import IndexPage from "./404";

const Work = (data) => {
    const [images, setImages] = useState([]);
    const [item, setItem] = useState([]);
    const [initialized, setInitialized] = useState(false);


    const fetchData = () => {
        return fetch("https://tlknitting.com/service/work/" + data.id)
            .then((response) => response.json())
            .then((data) =>  {
                setImages(data.images);
                setItem(data);
                setInitialized(true);
            });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const gContext = useContext(GlobalContext);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: images.length > 1 ? 2:1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        focusOnSelect: false
    };

    if (!initialized) {
        return (
            <>
                <PageWrapper><Spinner/></PageWrapper>
            </>
        );
    }

    document.title = item.name;

    if (!item.id) {
        return (
            <IndexPage></IndexPage>
        );
    }
    return (
        <>
            <PageWrapper>
                <Container
                    css={`
            margin-top: 92px;
            padding-bottom: 0;
          `}>
                    <Slider {...settings}>
                        {images.map((image, index) => (
                            <div className={"p-1"} key={index}>
                                <ImageZoom src={image} alt="" className="w-100" css={images.length === 1 ? `
            max-width: 500px
          `:``}/>
                            </div>
                        ))}
                    </Slider>
                </Container>
                <Section>
                    <Container>
                        <Row>
                            <Col lg="8">
                                <Title variant="secSm" className="my-4">
                                    {item.name}
                                </Title>
                                <Text
                                    variant="p"
                                    css={`
                    max-width: 750px;
                  `}
                                >
                                    <div dangerouslySetInnerHTML={ { __html: item.description } }></div>
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <div className="mt-lg-3">
                    <Container>
                        <Row>
                            <Col lg="3" className={item.country === null ? 'd-none':''}>
                                <Text variant="tag">Пряжа</Text>
                                <Title variant="cardBig" className="mt-3">
                                    {item.country}
                                </Title>
                            </Col>
                            <Col lg="4">
                                <Text variant="tag">Состав</Text>
                                <Title variant="cardBig" className="mt-3">
                                    <div dangerouslySetInnerHTML={ { __html: item.contains } }></div>
                                </Title>
                            </Col>
                            <Col lg="5">
                                <Text variant="tag">Цена{item.size ? ' в размере' + item.size:''}*</Text>
                                <Title variant="cardBig" className="mt-3">
                                    {item.price} BYN
                                </Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="7" className={"d-flex align-items-center"}>
                                <Text variant="small">* цена зависит от размера и стоимости пряжи на момент заказа</Text>
                            </Col>
                            <Col lg="5" className={"mt-2"}>
                                <Button arrowRight onClick={(e) => {
                                    e.preventDefault();
                                    gContext.toggleOrder();
                                }}>Оставить заявку</Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </PageWrapper>
        </>
    );
};
export default Work;